<template>
  <div>
    <b-alert show variant="info" v-if="isLoading">
      <b-icon icon="arrow-counterclockwise" animation="spin-reverse"></b-icon>
      cargando datos...
    </b-alert>
    <v-card v-else-if="worksessionData" class="dailysummary">
      <v-card-title
        class="text--primary d-flex align-center justify-content-between"
      >
        <span class="text-h6">
          {{ `${this.user.name} ${this.user.surname}` }}
        </span>
        <span class="text-h5">Resumen Jornada</span>
        <span class="text-h6">
          {{ moment(worksessionData?.date).format("DD/MM/YYYY") }}
        </span>
      </v-card-title>
      <v-divider class="mx-4 my-0"></v-divider>
      <v-card-text class="text--primary">
        <h6 class="title_section">Cumplimiento de Rutero</h6>
        <div class="container">
          <h6 class="title_group">Rutero de Hoy</h6>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <div class="container">
                <v-row>
                  <v-col cols="12" sm="12" md="6">Clientes a visitar</v-col>
                  <v-col cols="12" sm="12" md="6">
                    {{ summary_constants?.COUNT_POS_TO_VISIT }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">Clientes Visitados</v-col>
                  <v-col cols="12" sm="12" md="6">
                    {{ summary_constants?.COUNT_POS_VISITED }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">Clientes Pendientes</v-col>
                  <v-col cols="12" sm="12" md="6">
                    {{ summary_constants?.COUNT_POS_PENDING_VISIT }}
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <div class="container">
                <v-row>
                  <v-col cols="12" sm="12" md="6">%Cumpl Rutero</v-col>
                  <v-col cols="12" sm="12" md="6">
                    {{ summary_constants?.PERCENT_VISITED_TO_VISIT_POS_LABEL }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    %Cumpl Rutero con Añadidos
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    {{ summary_constants?.PERCENT_ADD_RECOVERED_POS_LABEL }}
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <h6 class="title_group">Resto Clientes</h6>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <div class="container">
                <v-row>
                  <v-col cols="12" sm="12" md="6">Clientes Recuperados</v-col>
                  <v-col cols="12" sm="12" md="6">
                    {{ summary_constants?.COUNT_ADDITIONAL_POS_PENDING_VISIT }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">Clientes Añadidos</v-col>
                  <v-col cols="12" sm="12" md="6">
                    {{ summary_constants?.COUNT_RESTOF_ADDITIONAL_POS }}
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <div class="container">
                <v-row>
                  <v-col cols="12" sm="12" md="6"
                    >Clientes cambio tipo visita</v-col
                  >
                  <v-col cols="12" sm="12" md="6">
                    {{ summary_constants?.COUNT_CHANGED_VISITTYPE_POS }}
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-divider class="my-2"></v-divider>
        <h6 class="title_section">Perfect Store</h6>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
            class="d-flex justify-content-center text-center"
          >
            <table
              class="table b-table table-striped table-bordered table-sm b-table-stacked-sm"
              v-if="visitedBrands.length > 0"
            >
              <tr>
                <th></th>
                <th>Objectivo</th>
                <th>Real</th>
                <th>Vs Ant</th>
              </tr>
              <template v-if="visitedBrands.length > 0">
                <template v-for="(brandItem, index) of visitedBrands">
                  <tr :key="`ps_table_tr_${index}`">
                    <td>{{ brandItem.name }}</td>
                    <td>
                      {{
                        numberFormatEs(
                          brandItem.sum_objective_value / brandItem.repeatCount
                        )
                      }}
                    </td>
                    <td>
                      {{
                        numberFormatEs(
                          brandItem.ps_score_current / brandItem.repeatCount
                        )
                      }}
                    </td>
                    <td>
                      {{
                        formatVSLast(
                          brandItem.sum_diff_current_last /
                            brandItem.repeatCount
                        )
                      }}
                    </td>
                  </tr>
                </template>
              </template>
              <tr v-else>
                <td colspan="4">Empty !</td>
              </tr>
              <tr v-if="psBrandsTotal && Object.keys(psBrandsTotal).length > 0">
                <td>Total</td>
                <td>{{ numberFormatEs(psBrandsTotal?.objective) }}</td>
                <td>{{ numberFormatEs(psBrandsTotal?.real) }}</td>
                <td>{{ formatVSLast(psBrandsTotal?.vs_last) }}</td>
              </tr>
            </table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" class="ml-2 white--text" @click="onClickPrev">
          <b-icon icon="arrow-left"></b-icon> volver
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" class="ml-2 white--text" @click="onClickNext">
          {{ $t("Next") }} <b-icon icon="arrow-right"></b-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import moment from "moment";

export default {
  name: "DailySummaryByRoute",
  props: ["id"],
  components: {},
  data: function() {
    return {
      isLoading: true,
      summary_constants: {},
      psBrandsTotal: {},
      visitedBrands: [],
      worksessionData: null
    };
  },
  watch: {},
  computed: {
    ...mapGetters("auth", ["user"])
  },
  methods: {
    moment,
    formatVSLast(val) {
      let retStr = this.numberFormatEs(val);
      if (val > 0) {
        retStr = `+ ${this.numberFormatEs(val)}`;
      }
      return retStr;
    },
    onClickPrev() {
      // go to dashboard
      this.$router.push({
        name: "router_worksession_dashboard"
      });
    },
    onClickNext() {
      // go to daily summary page
      this.$router.push({
        name: "router_worksession_dailysummarybypos",
        params: {
          id: this.id
        }
      });
    },
    async init() {
      if (this.id) {
        this.isLoading = true;
        let form_data = {
          worksessionId: this.id
        };
        const {
          summary_constants,
          visitedBrands,
          psBrandsTotal,
          meta,
          worksessionData
        } = await ApiService.post(
          `worksession/get_daily_summary_phrase_1`,
          form_data
        );
        this.summary_constants = summary_constants;
        this.visitedBrands = visitedBrands;
        this.psBrandsTotal = psBrandsTotal;
        this.worksessionData = worksessionData;
        this.isLoading = false;
      }
    }
  },
  async updated() {},
  async mounted() {
    this.init();
  }
};
</script>

<style>
.dailysummary .title_section {
  background: #ddd;
  padding: 1rem;
  text-align: center;
}
.dailysummary .title_group {
  padding: 0.5rem;
  text-align: left;
  text-decoration: underline;
  margin: 0;
}
</style>
